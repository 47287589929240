<template>
  <div class="home-page">
    <img class="home-bg" src="../assets/bg.png" alt="" />
    <div
      class="home"
      :style="{
        scale: +windowAspectRatio > 1.8 ? 0.88 : 1,
        top: +windowAspectRatio > 1.8 ? '-2.5vw' : 0,
      }"
    >
      <div class="header">
        <div class="back">
          <img class="back-icon" src="../assets/left.png" />返回系统
        </div>
        <div class="title">
          <img class="title-img" src="../assets/shop/title-bg.png" alt="" />
          <div class="title-text">存量房数据分析看板</div>
        </div>
        <div class="current-time current-time-postion">{{ currentDate }}</div>
        <div class="current-time" style="visibility: hidden">
          2024-01-05 12:06:20
        </div>
      </div>
      <div class="container">
        <div class="container-left">
          <div class="container-left-box">
            <div class="container-left-box-title">存量房成交量统计</div>
            <div class="container-left-box-content">
              <div class="clbc-nav">
                <div class="conce-center-nav-dropdown">
                  <el-date-picker
                    v-model="currentSelectAllDate"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    range-separator="至"
                    unlink-panels
                    :picker-options="pickerOptionsAll"
                    @change="allDatePickerChange"
                    :clearable="false"
                    >
                </el-date-picker>
              </div>
              </div>
              <div class="clbc-data">
                <div class="clbc-data-title">成交总数</div>
                <div class="clbc-data-content">
                  <div class="clbc-data-content-num" v-for="(item, index) in hourseTurnTotal" :key="index"><numberScroll :value="item" :stringNumber="true" :time="500"></numberScroll></div>
                  <div class="clbc-data-content-unit">套</div>
                </div>
              </div>
              <div class="clbc-cards">
                <div class="clbc-card" v-for="(item, index) in hourseTurnOver" :key="index" >
                  <div class="clbc-card-title">{{ item.district }}</div>
                  <div class="clbc-card-content">成交数: <span><numberScroll :value="item.total" :time="10"></numberScroll></span></div>
                 
                </div>
              </div>
            </div>
          </div>
          <div class="container-left-box">
            <div class="container-left-box-title">成交量区域统计</div>
            <div class="container-left-box-content">
                <div class="pieChart" ref="pieChart"></div>
            </div>
          </div>
        </div>
        <div class="container-center">
          <div class="conce-top">
            <div class="conce-circle">
                <div class="conce-circle-box">
                  <div class="conce-circle-title" v-if="houseCountData.period">环比
                  <div><numberScroll :value="houseCountData.period.mom" :time="30"></numberScroll>%</div></div>
                  <div class="conce-circle-title" v-if="!houseCountData.period">环比0%</div>
                </div>
                <div class="conce-circle-content" v-if="houseCountData.period"><numberScroll :value="houseCountData.period.before_last_period" :time="30"></numberScroll>套</div>
                <div class="conce-circle-content" v-if="!houseCountData.period">0套</div>
                <div class="conce-circle-footer">前两个月成交量</div>
              </div>
              <div class="conce-circle">
                <div class="conce-circle-box conce-circle-box2">
                  <div class="conce-circle-title" v-if="houseCountData.period">同比
                  <div><numberScroll :value="houseCountData.period.yoy" :time="30"></numberScroll>%</div></div>
                  <div class="conce-circle-title" v-if="!houseCountData.period">同比0%</div>
                </div>
                <div class="conce-circle-content" v-if="houseCountData.period"><numberScroll :value="houseCountData.period.last_month_period" :time="30"></numberScroll>套</div>
                <div class="conce-circle-content" v-if="!houseCountData.period">0套</div>
                <div class="conce-circle-footer">上月成交量</div>
              </div>
              <div class="conce-circle">
                <div class="conce-circle-content" v-if="houseCountData.period"><numberScroll :value="houseCountData.period.last_year_period" :time="30"></numberScroll>套</div>
                <div class="conce-circle-content" v-if="!houseCountData.period">0套</div>
                <div class="conce-circle-footer">去年同期成交量</div>
              </div>
            <!-- <div class="conce-top-footer">一周期=近30天</div> -->
          </div>
          <div class="conce-center">
            <div class="conce-center-nav">
              <div class="conce-center-nav-title">地区数据环比</div>
              <div class="conce-center-nav-dropdown">
                <el-date-picker
                  v-model="currentSelectDate"
                  type="month"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  @change="datePickerChange"
                  :clearable="false"
                  >
                </el-date-picker>
              </div>
            </div>
            <div class="conce-center-bar" ref="conceCenterBar"></div>
          </div>
          <div class="conce-bottom">
            <div class="conce-bottom-left">
              <div class="conce-bottom-left-title">面积</div>
              <div class="conce-bottom-left-content" ref="conceBottomLeftPie"></div>
            </div>
            <div class="conce-bottom-right">
              <div class="conce-bottom-right-title">金额</div>
              <div class="conce-bottom-right-content" ref="conceBottomRightPie"></div>

            </div>
          </div>
        </div>
        <div class="container-right">
          <div class="container-right-box">
            <div class="container-right-box-title">今日成交详情
              <div class="corner" v-if="rawTodayList">成交套数: {{rawTodayList.length}}套</div>
            </div>
            <div class="container-right-box-content">
              <div class="crbc-table" :style="{transform: `translateY(${-tableHeight}vw)`, Transition: `all ${isTransition ? '1s' : ''}`}">
                <div class="crbc-table-item" v-for="(item, index) in houseCountData.today_list" :key="index">
                  <div>{{ item.release_time }}</div>
                  <div>{{ item.addr }}</div>
                  <div class="crbc-table-item-num">{{item.price}}万</div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-right-box2">
            <div class="container-right-box-title">年统计对比</div>
            <div class="container-right-box-lineChart" ref="conriLine"></div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="footer-left">
          <div class="footer-left-title">年度月详情</div>
          <div class="footer-left-content" ref="footerLeftLine"></div>
        </div>
        <div class="footer-right">
          <div class="footer-right-title">周/日统计
            <div class="footer-right-title-dropdown">
              <div class="footer-right-title-dropdown-item" :style="{color: dropdownActive == 1 ? '#fff' : '', background: dropdownActive == 1 ? '#1684FC' : '#0167AD'}" @click="dropdownActiveChange(1)">周</div>
              <div class="footer-right-title-dropdown-item" :style="{color: dropdownActive == 2 ? '#fff' : '', background: dropdownActive == 2 ? '#1684FC' : '#0167AD'}" @click="dropdownActiveChange(2)">日</div>
            </div>
          </div>
          <div class="footer-right-content" ref="footerRightBar"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import { DatePicker } from "element-ui";
  import numberScroll from "../components/common/number-scroll.vue"

  function debounce(func, delay) {
    let timeoutId;
    
    return function(...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  export default {
    name: "MarketDashboardScreen",
    components: { numberScroll },
    data() {
      return {
        currentDate: this.formatDate(new Date()),
        timer: null,
        currentSelectDate: "2024-4",
        pieChart: null,
        barChart: null,
        conceBottomLeftPieChart: null,
        conceBottomRightPieChart: null,
        conriLineChart: null,
        footerLeftLineChart: null,
        footerRightBarChart: null,
        dateList: [
          {value: 40, name: 'rose 1' },
          {value: 40, name: 'rose 2' },
          {value: 40, name: 'rose 3' },
          {value: 40, name: 'rose 4' },
          {value: 40, name: 'rose 5' },
        ],
        option1: {
          tooltip: {
            trigger: 'item'
          },
          series: [
            {
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: this.$fontSize(0.20)
              },
              data: [
                { value: 40, name: '思明区' },
                { value: 38, name: '集美区' },
                { value: 32, name: '湖里区' },
                { value: 30, name: '同安区' },
                { value: 28, name: '海沧区' },
                { value: 26, name: '翔安区' },
              ]
            }
          ]
        },
        option2: {
          grid: {
            left: 70,
            top: 40,
            bottom: 30,
          },
          textStyle: {
            color: "#ffffff",
          },
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
              var result = params[0].name + '<br/>';
              params.forEach(function (item) {
                  result += '<span style="color:' + item.color + '">●</span> ' + item.seriesName + ': ' + item.value + ' 套<br/>'; // 在值前添加实心圆，并设置颜色
              });
              return result;
            },
            // axisPointer: {
            //   type: 'shadow'
            // }
          },
          legend: {
            data: ['近一周期', '上一周期'],
            textStyle: {
              color: "#ffffff",
            },
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: ['思明区', '集美区', '湖里区', '同安区', '海沧区', '翔安区'],
              axisLine: {       // y轴
                show: false   // 不显示y轴线
              },
            }
          ],
          yAxis: [
            {
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#3D6590',
                },
              },
              type: 'value',
              axisLabel: {
                formatter: "{value} 套",
              },
              textStyle: {
                color: "#fff",
              }
            }
          ],
          series: [
            {
              name: '近一周期',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value;
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    fontSize: this.$fontSize(0.12), //字体大小
                  },
                },
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '上一周期',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value;
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    fontSize: this.$fontSize(0.12), //字体大小
                  },
                },
            },
          ]
        },
        option3: {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            top: 'center',
            // width: 20,
            // height: 100,
            textStyle: {
              color: '#fff',
              fontSize: this.$fontSize(0.12)
            },
            itemWidth: this.$fontSize(0.20),
            itemHeight: this.$fontSize(0.12),
          },
          series: [
            {
              // name: 'Access From',
              type: 'pie',
              radius: '80%',
              center: ['35%', '50%'],
              labelLine: {
                show: false
              },
              label: {
                show: false
              },
              data: [
                { value: 1048, name: '40平以下' },
                { value: 735, name: '40平-90平' },
                { value: 580, name: '90平-140平' },
                { value: 484, name: '140平-200平' },
                { value: 300, name: '200平以上' }
              ]
            }
          ]
        },
        option4: {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'right',
            top: 'center',
            // width: 20,
            // height: 100,
            textStyle: {
              color: '#fff',
              fontSize: this.$fontSize(0.12)
            },
            itemWidth: this.$fontSize(0.20),
            itemHeight: this.$fontSize(0.12),
          },
          series: [
            {
              // name: 'Access From',
              type: 'pie',
              radius: '80%',
              center: ['35%', '50%'],
              labelLine: {
                show: false
              },
              label: {
                show: false
              },
              data: [
                { value: 1048, name: '100万以下' },
                { value: 735, name: '100-200万' },
                { value: 580, name: '200-300万' },
                { value: 484, name: '400-500万' },
                { value: 300, name: '500-800万' },
                { value: 300, name: '800万以上' }
              ]
            }
          ]
        },
        option5: {
          grid: {
            left: 70,
            top: 30,
            bottom: 50,
          },
          tooltip: {
            trigger: 'axis'
          },
          textStyle: {
            color: "#fff"
          },
          xAxis: {
            type: 'category',
            data: ['2022', '2023', '2024'],
            axisLabel: {
              formatter: "{value} 年",
            },
          },
          yAxis: {
            type: 'value',
            splitNumber: 3,
            axisLabel: {
              formatter: "{value} 套",
            },
          },
          series: [
            {
              name: "",
              data: [0, 0, 0],
              type: 'line',
              smooth: true,
              label: {
                show: true, //开启显示
                position: ['-1000%', '200%'], //在上方显示
                // offset: [20, 0],
                // formatter: [
                //     '{a|{c}套}',
                //     '{b|同比增长600%}'
                // ].join('\n'),
                formatter: (e) => {
                  // console.log("e", e)
                  let arr = []
                  if(this.houseCountData.year_list) {
                    if(this.houseCountData.year_list[e.dataIndex].growth_rate > 0) {
                      arr = [
                      `{a|${e.value}套}`,
                      `{b|同比增长${this.houseCountData.year_list[e.dataIndex].growth_rate}%}`,
                      ].join('\n')
                    }else{
                      arr = [
                      `{a|${e.value}套}`,
                      `{c|同比降低${this.houseCountData.year_list[e.dataIndex].growth_rate}%}`,
                      ].join('\n')
                    }
                  }
                  return arr
                },
                rich: {
                  a: {
                      color: '#fff',
                      lineHeight: this.$fontSize(0.10)
                  },
                  b: {
                      color: 'lightgreen',
                      fontSize: this.$fontSize(0.16),
                      height: this.$fontSize(0.40)
                  },
                  c: {
                      color: 'red',
                      fontSize: this.$fontSize(0.16),
                      height: this.$fontSize(0.40)
                  },
                },
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: this.$fontSize(0.10), //字体大小
                },
              },
            }
          ]
        },
        option6: {
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              // console.log("params", params)
              var result = params[0].name + '<br/>';
              params.forEach(function (item) {
                  result += `<span style="color:  ${item.color}  ">●</span> ${item.seriesName}:   <span style="margin-left: 3px">  ${item.value}  </span>   <span style="color: #01B2F9;">套</span><br/>`; // 在值前添加实心圆，并设置颜色
              }); 
              let chain = ''
              // console.log("this.listArr", this.listArr)
              params.forEach((item, idx) => {
                chain += `<span style="color: red;">${this.listArr[item.componentIndex][item.dataIndex].mom}%</span><br/>`; // 在值前添加实心圆，并设置颜色
              }); 
              let str = `<div style="display: flex;justify-content: space-between;"><div>${result}</div><div style="margin-left: 10px; color: red;">环比上月<br/>${chain}</div></div>`
              return str;
            },
          },
          legend: {
            textStyle: {
              color: "#fff"
            },
          },
          textStyle: {
            color: "#fff"
          },
          grid: {
            left: 90,
            top: 30,
            bottom: 30,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          },
          yAxis: {
            type: 'value',
            splitNumber: 3,
            axisLabel: {
              formatter: "{value} 套",
            },
          },
          series: [
            {
              name: '2022',
              type: 'line',
              // stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              name: '2023',
              type: 'line',
              // stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              name: '2024',
              type: 'line',
              // stack: 'Total',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
          ]
        },
        option7: {
          grid: {
            left: 50,
            top: 50,
            bottom: 20,
          },
          textStyle: {
            color: "#ffffff",
          },
          tooltip: {
            trigger: 'axis',
            // axisPointer: {
            //   type: 'shadow'
            // }
          },
          legend: {
            textStyle: {
              color: "#ffffff",
            },
            top: 10,
          },
          xAxis: [
            {
              type: 'category',
              axisTick: { show: false },
              data: ['7/20', '7/21', '7/22', '7/23', '7/24'],
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitNumber: 5,
              axisLabel: {
                formatter: "{value} 套",
              },
              textStyle: {
                color: "#fff",
              }
            }
          ],
          series: [
            {
              name: '思明区',
              type: 'bar',
              barGap: 0,
              emphasis: {
                focus: 'series'
              },
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
              data: [0, 0, 0, 0, 0],
            },
            {
              name: '集美区',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
            },
            {
              name: '湖里区',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
            },
            {
              name: '同安区',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
            },
            {
              name: '海沧区',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
            },
            {
              name: '翔安区',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: [0, 0, 0, 0, 0],
              label: {
                  show: false, //开启显示
                  position: "top", //在上方显示
                  // offset: [20, 0],
                  formatter: (e) => {
                    return e.value + "套";
                  }, //显示百分号
                  textStyle: {
                    //数值样式
                    color: "#fff", //字体颜色
                    // fontSize: 16, //字体大小
                  },
                },
            },
          ]
        },
        windowAspectRatio: 0,
        recentTime: ["全部", "近30天", "近90天", "近180天", "近360天"],
        currentRecentTime: "全部",
        hourseTurnOver: [],
        pickerOptions: {
          disabledDate(time) {
            // 获取当前日期
            const today = new Date();
            // 计算22年后的日期
            const futureYear = today.getFullYear() - 2;
            const futureMonth = today.getMonth() - 3;
            const futureDate = new Date(futureYear, futureMonth, 1);
            // 将今天的时间设置为0点，以便进行比较
            today.setHours(0, 0, 0, 0);
            // 如果当前时间小于22年后的日期，并且时间大于今天，则可以选择
            return time < futureDate || time > today;
          }
        },
        houseAreaTomList: [],
        hourseTurnTotal: [],
        houseCountData: {},
        currentSelectAllDate: [this.getCurrentDataFun(7), this.getCurrentDataFun()],
        pickerOptionsAll: {
          disabledDate(time) {
            // 获取当前日期
            const today = new Date();
            // 计算22年后的日期
            const futureYear = today.getFullYear() - 2;
            const futureMonth = today.getMonth() - 3;
            const futureDate = new Date(futureYear, futureMonth, 1);
            // 将今天的时间设置为0点，以便进行比较
            today.setHours(0, 0, 0, 0);
            // 如果当前时间小于22年后的日期，并且时间大于今天，则可以选择
            return time < futureDate || time > today;
          },
          shortcuts: [{
            text: '近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近1个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(Math.floor((start.getMonth() / 3)) * 3 - 1);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近1个季度',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(Math.floor((start.getMonth() / 3)) * 3 - 3);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近2个季度',  
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(Math.floor((start.getMonth() / 3)) * 3 - 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近3个季度',  
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(Math.floor((start.getMonth() / 3)) * 3 - 9);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近1年',  
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 1);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '近2年',  
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 2);
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '近3年',  
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setFullYear(start.getFullYear() - 3);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        tableHeight: 0,
        intervalId: null,
        rawTodayList: [],
        numberFirst: 0,
        isTransition: true,
        dropdownActive: 1,
        listArr: [],
        week_count_list: []
      };
    },

    mounted() {
      this.getHouseTurnOver(this.getCurrentDataFun(7), this.getCurrentDataFun());  
      this.getHouseAreaMom()
      this.getHouseCount()
      this.getHouseWeekCount()
      this.timer = setInterval(() => {
        this.currentDate = this.formatDate(new Date());
      }, 1000);
      this.initPieCharts();
      this.initConceCenterBar();
      this.initConceBottomLeftPie()
      this.initConceBottomRightPie()
      this.initConriLineChart()
      this.initFooterLeftLineChart()
      this.initFooterRightLineChart()
      this.handleResize();

      window.onresize = () => {
          this.pieChart && this.pieChart.resize();
          this.barChart && this.barChart.resize();
          this.conceBottomLeftPieChart && this.conceBottomLeftPieChart.resize();
          this.conceBottomRightPieChart && this.conceBottomRightPieChart.resize();
          this.conriLineChart && this.conriLineChart.resize();
          this.footerLeftLineChart && this.footerLeftLineChart.resize();
          this.footerRightBarChart && this.footerRightBarChart.resize();

          // this.conceBottomRightPieChart.setOption(this.option4)

          this.handleResize();
        };

        document.addEventListener('visibilitychange', this.handleVisibilityChange);
        let isFirst = false
        this.intervalId = setInterval(() => {
          if(!isFirst) {
            this.tableHeight += 3.5;
            isFirst = true
          }else{
            this.tableHeight += 2.3;
          }
          this.handleTodatListPush()
        }, 1000);
      },
    beforeDestroy() {
      this.pieChart.dispose();
      this.barChart.dispose();
      this.conceBottomLeftPieChart.dispose();
      this.conceBottomRightPieChart.dispose();
      this.conriLineChart.dispose();
      this.footerLeftLineChart.dispose();
      this.footerRightBarChart.dispose();
      clearInterval(this.timer);
      clearInterval(this.intervalId);
      document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    },

    methods: {
      formatTime(dateString) {
        // 创建 Date 对象
        const date = new Date(dateString);

        // console.log("date", dateString)
        // 获取年、月、日
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 月份从 0 开始，所以需要加 1
        const day = date.getDate();
        // 格式化成 YYYY-M-D
        const formattedDate = `${year}-${month}-${day}`; 
        return formattedDate
      },
      // 获取今日日期
      getCurrentDataFun(subtractDay = 0) {
        const currentDate = new Date();
        // 获取当前日期的年、月、日
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份从 0 开始，所以需要加 1
        const day = currentDate.getDate() - subtractDay;

        // 格式化成 YYYY-M-D
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
      },
      dropdownActiveChange(index) {
        this.dropdownActive = index
        if(index==2) {
          this.getHouseDayCount()
        }else{
          this.getHouseWeekCount()
        }
      },
      handleTodatListPush() {
        if(this.numberFirst == 0) {
          this.isTransition = true
        }
        this.numberFirst++ 
          // console.log("this.numberFirst", this.numberFirst, this.rawTodayList.length)
          if(this.numberFirst == this.rawTodayList.length) {
            if(this.houseCountData.today_list.length < 500) {
              this.houseCountData.today_list = this.houseCountData.today_list.concat(this.rawTodayList)
              this.isTransition = true
            }else{
              this.numberFirst = 0
              this.tableHeight = 0
              this.houseCountData.today_list = this.rawTodayList
              this.isTransition = false
            }
            this.numberFirst = 0
          }
      },
      handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
          // 当页面不可见时，清除定时器
          clearInterval(this.intervalId);
        } else {
          // 当页面可见时，重新启动定时器
          this.intervalId = setInterval(() => {
            this.tableHeight += 2.3;
            this.handleTodatListPush()
          }, 1000);
        }
      },
      datePickerChange() {
        this.getHouseAreaMom()
      },
      allDatePickerChange(e) {
        // console.log("eee", this.currentSelectAllDate)
        this.getHouseTurnOver(this.formatTime(e[0]), this.formatTime(e[1]))
      },
      formatDates(date) {
        if(date == "全部") {
          return 1
        }else if(date == "近30天") {
          return 2
        }else if(date == "近90天") {
          return 3
        }else if(date == "近180天") {
          return 4
        }else {
          return 5
        }
      },
      getHouseCount() {
        this.$http.post("dashboard/houseCount").then((res) => {
          if(res.data.code == 1) {
            this.houseCountData = res.data.data
            this.option5.xAxis.data = this.houseCountData.year_list.map(item => (item.year))
            this.option5.series[0].data = this.houseCountData.year_list.map(item => (item.total))
            let listArr = []
            Object.keys(this.houseCountData.year_detail_count_list).map(item => {
              // console.log("item", this.houseCountData.year_detail_count_list[item])
              let list = []
              Object.keys(this.houseCountData.year_detail_count_list[item]).forEach(key => {
                list.push(this.houseCountData.year_detail_count_list[item][key])
              })
              listArr.push(list)
            })
            // console.log("listArr", listArr)
            this.listArr = listArr
            listArr.map((item, index) => {
              // console.log("iyemm", item)
              this.option6.series[index].data = item.map(item => {
                return item.total
              })
            })
            // console.log("this.option6", this.option6)
            this.initConriLineChart()
            this.initFooterLeftLineChart()
            this.rawTodayList = JSON.parse(JSON.stringify(this.houseCountData.today_list))
          }
        })
      },
      getHouseAreaMom() {
        this.$http.post("dashboard/houseAreaMom", { time: this.currentSelectDate }).then((res) => {
          if(res.data.code == 1) {
            this.houseAreaTomList = res.data.data
            let keyLists = []
            let valueList1 = []
            let valueList2 = []
            Object.keys(this.houseAreaTomList.cur).forEach(key => {
              keyLists.push(key);
              this.option2.xAxis[0].data = keyLists
              valueList1.push(this.houseAreaTomList.cur[key])
              this.option2.series[0].data = valueList1
              valueList2.push(this.houseAreaTomList.last[key])
              this.option2.series[1].data = valueList2
              this.initConceCenterBar()
            });
            Object.keys(this.houseAreaTomList.money).forEach(key => {
              // console.log(+key - 1); // 输出每个键名
              const index = +key - 1
              if(index <= 4) {
                this.option3.series[0].data[index].value = this.houseAreaTomList.area[key]
                this.initConceBottomLeftPie()
              }
              this.option4.series[0].data[index].value = this.houseAreaTomList.money[key]
              this.initConceBottomRightPie()
            })
          }
        })
      },
      getHouseTurnOver(startTime, endTime) {
        this.$http.post("dashboard/houseTurnover", { start_time: startTime, end_time: endTime }).then((res) => {
            if (res.data.code === 1) {
              this.hourseTurnOver = res.data.data.list;
              const digits = res.data.data.total.toString().split('').map(Number);
              this.hourseTurnTotal = digits
              this.option1.series[0].data = this.hourseTurnOver.map(item => {
                return {
                  value: item.total,
                  name: item.district
                }
              })
              this.initPieCharts()
            } else {
              this.$message.error("获取数据失败");
            }
          });
      },
      getHouseDayCount() {
        this.$http.post("dashboard/houseDayCount", {}).then((res) => {
          if(res.data.code == 1) {
            this.week_count_list = res.data.data
          }
          let list3 = []
          let list4Arr = []
          let keyList4 = []
            Object.keys(this.week_count_list).map((item, idn) => {
              let list4 = []
              keyList4.push(item)
              Object.keys(this.week_count_list[item]).map((key, idx) => {
                  // console.log("key", key)
                  list4.push(this.week_count_list[item][key])
                  // this.option7.series[key].data.push(this.houseCountData.week_count_list[item][key])
              })

              list4Arr.push(list4)

              if(idn == 0) {
                Object.keys(this.week_count_list[item]).map((key, idx) => {
                  // console.log("key", key)
                  list3.push(key)
                  // this.option7.series[key].data.push(this.houseCountData.week_count_list[item][key])
                })
              }
            })

            // console.log("list4Arr", list4Arr)

            list4Arr = list4Arr[0].map((_, colIndex) => list4Arr.map(row => row[colIndex]))

            // // console.log("list4Arr", list4Arr)

            this.option7.xAxis[0].data = keyList4
            this.option7.series.map((item, index) => {
              item.name = list3[index]
              item.data = list4Arr[index]
              return item
            })
            // // console.log("this.options", this.option7)
            this.initFooterRightLineChart()
        })
      },
      getHouseWeekCount() {
        this.$http.post("dashboard/houseWeekCount", {}).then((res) => {
          if(res.data.code == 1) {
            this.week_count_list = res.data.data
          }
          let list3 = []
          let list4Arr = []
          let keyList4 = []
            Object.keys(this.week_count_list).map((item, idn) => {
              let list4 = []
              keyList4.push(item)
              Object.keys(this.week_count_list[item]).map((key, idx) => {
                  // console.log("key", key)
                  list4.push(this.week_count_list[item][key])
                  // this.option7.series[key].data.push(this.houseCountData.week_count_list[item][key])
              })

              list4Arr.push(list4)

              if(idn == 0) {
                Object.keys(this.week_count_list[item]).map((key, idx) => {
                  // console.log("key", key)
                  list3.push(key)
                  // this.option7.series[key].data.push(this.houseCountData.week_count_list[item][key])
                })
              }
            })

            // console.log("list4Arr", list4Arr)

            list4Arr = list4Arr[0].map((_, colIndex) => list4Arr.map(row => row[colIndex]))

            // // console.log("list4Arr", list4Arr)

            this.option7.xAxis[0].data = keyList4
            this.option7.series.map((item, index) => {
              item.name = list3[index]
              item.data = list4Arr[index]
              return item
            })
            // // console.log("this.options", this.option7)
            this.initFooterRightLineChart()
        })
      },
      // clbcNavTap(it) {
      //   console.log("it", it);
      //   this.currentRecentTime = it
      //   this.getHouseTurnOver()
      // },
      // dropdownTap(it) {
      //   console.log("it", it);
      //   this.currentSelectDate = it.name;
      // },
      initPieCharts() {
        let chartDom = this.$refs.pieChart;
        let pieChart = echarts.init(chartDom);
        this.pieChart = pieChart;
        // console.log("first111", this.$refs.pieChart, pieChart);
        this.option1 && pieChart.setOption(this.option1);
      },
      initConceCenterBar() {
        let chartDom = this.$refs.conceCenterBar;
        let barChart = echarts.init(chartDom);
        this.barChart = barChart;
        this.option2 && barChart.setOption(this.option2);
      },
      initConceBottomLeftPie() {
        let chartDom = this.$refs.conceBottomLeftPie;
        let Chart = echarts.init(chartDom);
        this.conceBottomLeftPieChart = Chart;
        this.option3 && Chart.setOption(this.option3);
      },
      initConceBottomRightPie() {
        let chartDom = this.$refs.conceBottomRightPie;
        let Chart = echarts.init(chartDom);
        this.conceBottomRightPieChart = Chart;
        this.option4 && Chart.setOption(this.option4);
      },
      initConriLineChart() {
        let chartDom = this.$refs.conriLine;
        let Chart = echarts.init(chartDom);
        this.conriLineChart = Chart;
        this.option5 && Chart.setOption(this.option5);
      },
      initFooterLeftLineChart() {
        let chartDom = this.$refs.footerLeftLine;
        let Chart = echarts.init(chartDom);
        this.footerLeftLineChart = Chart;
        this.option6 && Chart.setOption(this.option6);
      },
      initFooterRightLineChart() {
        let chartDom = this.$refs.footerRightBar;
        let Chart = echarts.init(chartDom);
        this.footerRightBarChart = Chart;
        this.option7 && Chart.setOption(this.option7);
      },
      handleResize() {
        // 获取窗口宽度和高度
        var windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        var windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        // 计算窗口长宽比
        var windowAspectRatio = windowWidth / windowHeight;
        // 打印结果或执行其他逻辑
        this.windowAspectRatio = windowAspectRatio;

        debounce(this.adjustWidthScreen, 1000)
      },
      adjustWidthScreen() {
        if(windowWidth < 1400) {
          this.option2.series[0].label.textStyle.fontSize = 10
          for(let i = 0; i < this.option7.series.length; i++) {
            this.option7.series[i].label.textStyle.fontSize = 8
          }
        }else{
          this.option2.series[0].label.textStyle.fontSize = "auto"
          for(let i = 0; i < this.option7.series.length; i++) {
            this.option7.series[i].label.textStyle.fontSize = "auto"
          }
        }
        this.initConceCenterBar()
        this.initFooterRightLineChart()
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = this.padZero(date.getMonth() + 1);
        const day = this.padZero(date.getDate());
        const hours = this.padZero(date.getHours());
        const minutes = this.padZero(date.getMinutes());
        const seconds = this.padZero(date.getSeconds());
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      padZero(value) {
        return value < 10 ? "0" + value : value;
      },
    },
  };
</script>

<style scoped lang="scss">
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
div{
  box-sizing: border-box;
}
  .header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .home-page {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .home-bg {
    width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }

  .home {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 0 20px;
    // background-color: #000;
    box-sizing: border-box;

    .header {
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
    }

    .back {
      color: #06ffff;
      display: flex;
      align-items: center;
      font-size: 7px;
      cursor: pointer;
      visibility: hidden;
      .back-icon {
        width: 5px;
        margin-right: 4px;
      }
    }

    .title {
      font-size: 6px;
      width: 515px;
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .title-img {
        width: 100%;
        height: 100%;
      }
      .title-text{
        position: absolute;
        color: #fff;
        font-size: 9px;
        font-weight: 700;
        top: 6.5px;
      }
    }
    
    .current-time {
      color: #27AD4C;
      font-size: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .current-time-postion {
      position: absolute;
      top: 10px;
      right: 20px;
    }
  }

  .container{
    display: flex;
    justify-content: space-between;
    .container-left{
      width: 190px;
      height: 255px;
      margin-top: -5px;
      // border: 1px solid #06ffff;
      .container-left-box{
        background: #032750;
        width: 100%;
        height: 50%;
        .container-left-box-title{
          width: 100%;
          height: 15px;
          background: #0366AC;
          color: #06ffff;
          display: flex;
          align-items: center;
          padding-left: 5px;
          font-size: 7px;
        }
        .container-left-box-content{
          padding: 3px 0;
          .clbc-nav{
            display: flex;
            padding-left: 3px;
            .clbc-nav-item{
              text-decoration: underline;
              color: #fff;
              font-size: 6px;
              margin: 3px 5px;
              cursor: pointer;
            }
          }
          .clbc-data{
            padding: 5px 5px 5px 12px;
            .clbc-data-title{
              color: #06ffff;
              font-size: 5px;
            }
            .clbc-data-content{
              display: flex;
              margin-top: 5px;
              .clbc-data-content-num{
                width: 10px;
                height: 20px;
                background-image: url("../assets/shop/num_bg.png");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 10px;
                margin: 0 1.2px;
              }
              .clbc-data-content-unit{
                color: #06ffff;
                margin-left: 3px;
                width: 10px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .clbc-cards{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 5px 12px;
            .clbc-card{
              color: #fff;
              display: flex;
              font-size: 7px;
              width: 48%;
              display: flex;
              margin-bottom: 3px;
              // align-items: center;
              .clbc-card-content{
                margin-left: 5px;
                border-bottom: 1px solid #A9B5C3;
                height: 9px;
                span{
                  margin-left: 3px;
                  color: #E49961;
                  // font-size: 8px;
                }
              }
            }
          }

          .pieChart{
            width: 100%;
            height: 98px;
          }
        }
      }
    }
    .container-center{
      width: 320px;
      height: 265px;
      // border: 1px solid #06ffff;
      .conce-top{
        background: #023362;
        width: 100%;
        height: 25%;
        display: flex;
        align-items: center;
        padding: 0 45px;
        justify-content: space-between;
        position: relative;
        .conce-top-left{
          display: flex;
          width: 33%;
          justify-content: space-between;
        }
        .conce-top-right{
          display: flex;
          width: 33%;
          justify-content: space-between;
        }
        .conce-top-footer{
          position: absolute;
          bottom: 1.2px;
          right: 3px;
          font-size: 4.5px;
          color: #01B2F9;
        }
        .conce-circle{
            width: 50px;
            height: 50px;
            border: 2.2px solid #057FD2;
            border-radius: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            .conce-circle-box{
              // padding: 0px 2px;
              background: #023362;
              width: 32px;
              height: 18px;
              position: absolute;
              top: 3px;
              left: 55.8px !important;
              box-sizing: content-box;
              .conce-circle-title{
                width: 100%;
                height: 100%;
                color: #01B2F9;
                font-size: 6px;
                border: 1px solid #01B2F9;
                background: #113C6F;
                text-align: center;
                border-radius: 3px;
                // @extend .flex-center;
              }
            } 
            .conce-circle-box2{
              top: 3px !important;
              left: 48.4px !important;
            } 
            .conce-circle-content{
              margin-top: 10px;
              color: #fff;
              font-size: 8px;
            }
            .conce-circle-footer{
              width: 30px;
              color: #01B2F9;
              margin-top: 1px;
              font-size: 6px;
              text-align: center;
            }
          }
      }
      .conce-center{
        width: 100%;
        height: 42%;
        margin-top: 4px;
        .conce-center-nav{
          font-size: 7px;
          width: 60%;
          display: flex;
          height: 15px;
          background: #0367AE;
          .conce-center-nav-title{
            color: #66E0DA;
            width: 50%;
            padding: 3px 3px 3px 7px;
            display: flex;
            letter-spacing: 3px;
          }
          .conce-center-nav-dropdown{
            width: 50%;
            // background: #0374C1;
            display: flex;
            justify-content: flex-end;

            .dropdown {
                padding: 2.5px 10px;
                // background: #0597c9;
                // background-image: url("../assets/select-bg.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 3px;
                font-size: 6px;
                display: flex;
                align-items: center;
                .el-dropdown-link {
                  color: #fff;
                  display: flex;
                  align-items: center;
                }

                .el-dropdown-img {
                  width: 8px;
                  height: 6px;
                  margin-left: 4px;
                  // margin-top: 1px;
                }
              }
              .el-icon-arrow-down {
                font-size: 8px;
              }
          }
        }
        .conce-center-bar{
          width: 100%;
          height: calc(100% - 15px);
          margin: 2px 0 0;
        }
      }
      .conce-bottom{
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        // margin-top: 3px;
        .conce-bottom-left{
          width: 43%;
          height: 100%;
          background: #032750;
          padding-right: 5px;
          position: relative;
          .conce-bottom-left-title{
            position: absolute;
            top: 5px;
            left: 5px;
            color: #59FFDD;
            font-size: 7px;
          }
          .conce-bottom-left-content{
            width: 100%;
            height: 100%;
          }
        }
        .conce-bottom-right{
          width: 55%;
          height: 100%;
          background: #032750;
          padding-right: 5px;
          position: relative;
          .conce-bottom-right-title{
            position: absolute;
            top: 5px;
            left: 5px;
            color: #59FFDD;
            font-size: 7px;
          }
          .conce-bottom-right-content{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .container-right{
      width: 190px;
      height: 265px;
      margin-top: -5px;
      // border: 1px solid #06ffff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .container-right-box{
        width: 100%;
        height: 42%;
        background: #032750;
        .container-right-box-title{
          width: 100%;
          height: 15px;
          background: #0366AC;
          color: #06ffff;
          display: flex;
          align-items: center;
          padding-left: 5px;
          font-size: 7px;
          position: relative;
          .corner{
            position: absolute;
            right: 2px;
            bottom: 2px;
            font-size: 5.1px;
            color: #fff;
          }
        }
        .container-right-box-content{
          width: 100%;
          height: calc(100% - 15px);
          overflow: hidden;
          .crbc-table{
            width: 100%;
            height: 100%;
            // overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 8px 0;
            // animation: scroll-up 15s infinite linear;
            // transition: all 1s;
            .crbc-table-item{
              padding: 5px 5px;
              width: 100%;
              color: #fff;
              display: flex;
              justify-content: space-between;
              font-size: 6px;
              .crbc-table-item-num{
                color: #E49961;
                font-size: 7px;
              }
            }
            .crbc-table-item:nth-child(odd) {
              background-color: #0F3C6D;
            }
          }
        }
      }
      .container-right-box2{
        width: 100%;
        height: 54%;
        background: #032750;
        .container-right-box-title{
          width: 100%;
          height: 15px;
          background: #0366AC;
          color: #06ffff;
          display: flex;
          align-items: center;
          padding-left: 5px;
          font-size: 7px;
        } 
        .container-right-box-lineChart{
          width: 100%;
          height: calc(100% - 15px);
        }
      }
    }
  }

  .footer{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .footer-left{
      width: 353px;
      height: 100px;
      // border: 1px solid #06ffff;
      background: #032750;
      .footer-left-title{
        width: 100%;
        height: 15px;
        background: #0366AC;
        color: #06ffff;
        display: flex;
        align-items: center;
        padding-left: 5px;
        font-size: 7px;
      }
      .footer-left-content{
        width: 100%;
        height: calc(100% - 15px);
      }
    }
    .footer-right{
      width: 353px;
      height: 100px;
      // border: 1px solid #06ffff;
      background: #032750;
      .footer-right-title{
        width: 100%;
        height: 15px;
        background: #0366AC;
        color: #06ffff;
        display: flex;
        align-items: center;
        padding-left: 5px;
        font-size: 7px;
        position: relative;

        .footer-right-title-dropdown{
          display: flex;
          position: absolute;
          top: 17px;
          cursor: pointer;
          z-index: 999;
          .footer-right-title-dropdown-item{
            color: #fff;
            padding: 1px 6px;
            background: #0167AD;
            cursor: pointer;
          }
          .footer-right-title-dropdown-item:nth-child(2) {
            
          }
        }
      }
      .footer-right-content{
        width: 100%;
        height: calc(100% - 15px);
      }
    }
  }

  ::v-deep .el-input__inner {
    background: #165aaf !important;
    border: 1px solid #165aaf;
    box-shadow: inset 1px 1px 2px 2px #164580;
    height: 15px;
    color: #fff;
  }

  ::v-deep .el-input__icon{
    line-height: 15px;
  }

  ::v-deep .popper__arrow::after {
    border-bottom-color: #165aaf !important;
  }

  ::v-deep .popper__arrow {
    border-bottom-color: #165aaf !important;
  }

  .el-dropdown-menu__item {
    color: #fff !important;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #000 !important;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #000;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  ::v-deep .el-date-editor--daterange.el-input__inner .el-range-input{
    background: #0c315e !important;
    color: #fff;
    width: 60px;
    border-radius: 3px;
  }

  ::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 170px;
  }

  ::v-deep .el-input__icon{
    display: flex;
    align-items: center;
  }

  ::v-deep .el-date-editor .el-range-separator{
    color: #ffffffcc;
    line-height: 12px;
  }

  @keyframes scroll-up {
  from {
    transform: translateY(0); /* 初始位置 */
  }
  to {
    transform: translateY(-800px); /* 结束位置 */
  }
}
</style>
