
<template>
  <span class="number-scroll-grow">
    <span
      ref="numberScroll"
      :data-time="time"
      class="number-scroll"
      :data-value="value">
      0
      </span>
  </span>
</template>
 
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "numberScroll",
  props: {
    time: {
      type: Number,
      default: 2,
    },
    value: {
      type: Number,
      default: 0,
    },
    thousandSign: {
      type: Boolean,
      default: () => false,
    },
    stringNumber: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      oldValue: 0,
    };
  },
  watch: {
    value: function (value, oldValue) {
      this.numberScroll(this.$refs.numberScroll);
    },
  },
  methods: {
    numberScroll(ele) {
      let _this = this;
      let value = _this.value - _this.oldValue;
      let step = (value * 10) / (_this.time * 100);
      if(this.stringNumber) {
        step = (value * 568) / (_this.time * 100);
        console.log('step', step)
      }
      let current = 0;
      let start = _this.oldValue;
      let t = setInterval(function () {
        start += step;
        if (start > _this.value) {
          clearInterval(t);
          start = _this.value;
          t = null;
        }
        if (current === start) {
          return;
        }
        current = parseInt(start);
        _this.oldValue = current;
        if (_this.thousandSign) {
          ele.innerHTML = current
            .toString()
            .replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, "$1,");
        } else {
          ele.innerHTML = current.toString();
        }
      }, 10);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.numberScroll(this.$refs.numberScroll);
    });
  },
});
</script>
 
<style lang="scss" scoped>
.number-scroll-grow {
  transform: translateZ(0);
}
</style>