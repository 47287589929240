<template>
  <div class="home-page">
    <!-- <div style="color: #fff">{{ windowAspectRatio }}</div> -->
    <img class="home-bg" src="../assets/bg.png" alt="" />
    <div
      class="home"
      :style="{
        scale: +windowAspectRatio > 1.8 ? 0.85 : 1,
        top: +windowAspectRatio > 1.8 ? '-2.5vw' : 0,
      }"
    >
      <div class="header">
        <div class="back">
          <img class="back-icon" src="../assets/left.png" />返回系统
        </div>
        <div class="title">
          <img class="title-img" src="../assets/header.png" alt="" />
        </div>
        <div class="current-time current-time-postion">{{ currentDate }}</div>
        <div class="current-time" style="visibility: hidden">
          2024-01-05 12:06:20
        </div>
      </div>
      <div class="container">
        <div class="container-left">
          <div class="tag-tab">
            <div class="tag-tab-box">
              <div class="tag-item">
                <img
                  v-show="currentTab == 0"
                  class="tag-bg"
                  src="../assets/tag-active.png"
                  alt=""
                />
                <img
                  v-show="currentTab != 0"
                  class="tag-bg"
                  src="../assets/tag.png"
                  alt=""
                />
                <div
                  class=""
                  :class="
                    currentTab == 0 ? 'tag-tab-item-active' : 'tag-tab-item'
                  "
                  @click="tagTabTap(0)"
                >
                  申报
                </div>
              </div>
              <div class="tag-tab-title">{{ totalData.apply_count }}家</div>
            </div>
            <div class="tag-tab-box">
              <div class="tag-item">
                <img
                  v-show="currentTab == 1"
                  class="tag-bg"
                  src="../assets/tag-active.png"
                  alt=""
                />
                <img
                  v-show="currentTab != 1"
                  class="tag-bg"
                  src="../assets/tag.png"
                  alt=""
                />
                <div
                  :class="
                    currentTab == 1 ? 'tag-tab-item-active' : 'tag-tab-item'
                  "
                  @click="tagTabTap(1)"
                >
                  核验
                </div>
              </div>
              <div class="tag-tab-title">{{ totalData.examine_count }}家</div>
            </div>
            <div class="tag-tab-box">
              <div class="tag-item">
                <img
                  v-show="currentTab == 2"
                  class="tag-bg"
                  src="../assets/tag-active.png"
                  alt=""
                />
                <img
                  v-show="currentTab != 2"
                  class="tag-bg"
                  src="../assets/tag.png"
                  alt=""
                />
                <div
                  :class="
                    currentTab == 2 ? 'tag-tab-item-active' : 'tag-tab-item'
                  "
                  @click="tagTabTap(2)"
                  style="width: 2.6vw"
                >
                  核验通过
                </div>
              </div>
              <div class="tag-tab-title">
                {{ totalData.examine_passed_count }}家
              </div>
            </div>
            <div class="tag-tab-box">
              <div class="tag-item">
                <img
                  v-show="currentTab == 3"
                  class="tag-bg"
                  src="../assets/tag-active.png"
                  alt=""
                />
                <img
                  v-show="currentTab != 3"
                  class="tag-bg"
                  src="../assets/tag.png"
                  alt=""
                />
                <div
                  :class="
                    currentTab == 3 ? 'tag-tab-item-active' : 'tag-tab-item'
                  "
                  @click="tagTabTap(3)"
                >
                  签约
                </div>
              </div>
              <div class="tag-tab-title">{{ totalData.sign_count }}家</div>
            </div>

            <div class="tag-line"></div>
            <div class="tag-line"></div>
            <div class="tag-line"></div>
            <div class="tag-title">统计流程</div>
            <img class="tag-tab-img" src="../assets/bg1.png" alt="" />
          </div>
          <div class="map">
            <div class="backChart" @click="backChartTap">
              {{ currentTitle }}
            </div>
            <div class="map-title">区域选择</div>
            <div class="tab">
              <div
                class="tab-it"
                :class="current == 0 ? 'tab-item-active' : 'tab-item'"
                @click="tabTap(0)"
              >
                福建省
              </div>
              <div
                class="tab-it"
                :class="current == 1 ? 'tab-item-active' : 'tab-item'"
                @click="tabTap(1)"
              >
                江西省
              </div>
            </div>
            <div class="map-chart">
              <img class="img-chart" src="../assets/bg2.png" alt="" />
              <div class="MapArea" ref="MapArea"></div>
              <!-- <div class="mapImg">
                <img class="mapImg-bg4" src="../assets/bg4.png" alt="" />
                <img class="mapImg-map" src="../assets/map.png" alt="" />
              </div> -->
            </div>
          </div>
        </div>
        <div class="container-right">
          <img class="container-right-img" src="../assets/border3.png" alt="" />
          <div class="container-right-title">数据与环比</div>
          <div class="container-right-content" v-show="currentLevel != 2">
            <div class="content-nav">
              <el-dropdown class="dropdown" @command="dropdownTap">
                <span class="el-dropdown-link">
                  {{ currentCity.name }}
                  <img
                    class="el-dropdown-img"
                    src="../assets/bottom.png"
                    alt=""
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in cityList"
                    :key="index"
                    :command="item"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div class="tag-time">
                <div
                  :class="
                    currentTabTime == index
                      ? 'tag-time-item-active'
                      : 'tag-time-item'
                  "
                  @click="tagTimeTap(index)"
                  v-for="(item, index) in timeList"
                  :key="index"
                >
                  <img
                    v-show="currentTabTime != index"
                    class="tag-time-img"
                    src="../assets/select-bg1.png"
                    alt=""
                  />
                  <img
                    v-show="currentTabTime == index"
                    class="tag-time-img"
                    src="../assets/select-active-bg1.png"
                    alt=""
                  />
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="content">
              <div class="charts">
                <div ref="barChart" class="barChart"></div>
                <div style="color: #fff; font-size: 0.7vw">
                  企业较{{ content_text }}对比
                </div>
                <div class="card-box">
                  <div class="card">
                    <div
                      class="card-item"
                      v-for="(item, index) in currentleftData"
                      :key="index"
                    >
                      <img class="" src="../assets/card-bg.png" alt="" />
                      <div class="city-name" style="text-align: center">
                        {{ item.area }}
                      </div>
                      <div class="cardChart" ref="cardChart"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-right-content" v-show="currentLevel == 2">
            <div class="area">
              <el-dropdown class="dropdown" @command="dropdownYearTap">
                <span class="el-dropdown-link">
                  {{ selectYear }}年
                  <img
                    class="el-dropdown-img"
                    src="../assets/bottom.png"
                    alt=""
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in years"
                    :key="index"
                    :command="item"
                    >{{ item }}年</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                class="dropdown"
                @command="dropdownMonthTap"
                style="margin-left: 0.9vw"
              >
                <span class="el-dropdown-link">
                  {{ selectMonth }}月
                  <img
                    class="el-dropdown-img"
                    src="../assets/bottom.png"
                    alt=""
                  />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in months"
                    :key="index"
                    :command="item"
                    >{{ item }}月</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="areaChart">
              <div class="monthChart" ref="monthChart"></div>
              <div class="yearChart" ref="yearChart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import Board from "@/components/Board.vue";
  import chinaJson from "@/assets/map/china.json";
  import { getDaysInMonth } from "@/utils/uitls";

  let regionsList = [];

  export default {
    name: "HomeView",
    components: {
      Board,
    },
    data() {
      return {
        currentLevel: 0, //1 省份下市区, 2 市级下县区  3 县区
        currentClick: null,
        currentClick2: null,
        currentTitle: "",
        currentCityList: [],
        option: {
          tooltip: {
            show: true,
            // triggerOn: 'click',
            // alwaysShowContent: true,
            formatter: "",
          },
          geo: {
            map: "chinaJson", //china可以显示右下加南海诸岛，别的名称无法显示
            roam: false,
            zoom: 1.2,
            aspectScale: 0.9, // 拉伸地图 值为0-1
            regions: regionsList,
            tooltip: {
              show: true,
              triggerOn: "click",
              formatter: (e) => {
                return `${this.getRegionValue(e.name)}`;
              },
              extraCssText: "width:140px; white-space:pre-wrap",
            },
            itemStyle: {
              normal: {
                // 默认地图区域样式
                areaColor: "#0398D6", // 设置背景颜色
                borderColor: "#06FFFF",
                borderWidth: 1.5,
                color: "#0070BC",
                shadowColor: "#0070BC",
                shadowOffsetY: 25,
                shadowBlur: -25,
              },
              emphasis: {
                // 鼠标悬浮地图区域样式
                show: true,
                areaColor: "#0075FF",
              },
            },
            label: {
              show: true,
              color: "#fff",
              rich: {
                a: {
                  color: "blue",
                },
                b: {
                  color: "black",
                },
              },
            },
            emphasis: {
              // 设置鼠标移上去hover效果
              // show: true,
              label: {
                color: "#000",
              },
            },
          },
          // series: [
          //   {
          //     name: "标记点",
          //     type: "effectScatter",
          //     coordinateSystem: "geo",
          //     data: [
          //       // { name: "北京", value: [116.334224882803, 28.7497585273538] },
          //       { name: "上海", value: [116.334224882622, 28.7497585273736] },
          //       { name: "南昌", value: [116.534254882812, 28.7497585279846] },
          //       // 添加更多标记点...
          //     ],
          //     symbolSize: 10,
          //     showEffectOn: "render", // 高亮效果的触发时机
          //     rippleEffect: {
          //       brushType: "stroke", // 波纹的绘制方式，支持 stroke 和 fill
          //     },
          //     hoverAnimation: true,
          //     label: {
          //       emphasis: {
          //         show: true,
          //         position: "right",
          //         formatter: "{b}",
          //       },
          //     },
          //     itemStyle: {
          //       normal: {
          //         color: "yellow",
          //         shadowBlur: 10,
          //         shadowColor: "rgba(0, 0, 0, 0.5)",
          //       },
          //     },
          //   },
          // ],
        },
        option1: {
          color: ["#0057FF", "#71FFD8", "#73BBFF"],
          textStyle: {
            color: "#ffffff",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: 35,
            top: 90,
          },
          legend: {
            data: ["装修公司", "材料商", "设计师"],
            textStyle: {
              color: "#ffffff",
              fontSize: "14px",
            },
            top: 0,
            right: 140,
          },
          calculable: false,
          xAxis: [
            {
              type: "category",
              axisLabel: {
                interval: 0, // 强制显示所有标签
                // rotate: 45,  // 旋转角度
                margin: 10, // 与轴线的距离
              },
              splitArea: {
                show: true,
              },
              data: [
                "南昌市",
                "景德镇市",
                "萍乡市",
                "九江市",
                "新余市",
                "鹰潭市",
                "赣州市",
                "吉安市",
                "宜春市",
                "抚州市",
                "上饶市",
              ],
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "装修公司",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: false, //开启显示
                position: "top", //在上方显示
                offset: [20, 0],
                formatter: (e) => {
                  // console.log("firstee", e)
                  let total = 0;
                  if (this.barChartSeries.series1) {
                    total =
                      +this.barChartSeries.series1[e.dataIndex] +
                      +this.barChartSeries.series2[e.dataIndex] +
                      +this.barChartSeries.series3[e.dataIndex];
                  }
                  return total + "家";
                }, //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "材料商",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: false, //开启显示
                position: "top", //在上方显示
                // offset: [20, 0],
                formatter: (e) => {
                  // console.log("firstee", e)
                  let total = 0;
                  if (this.barChartSeries.series1) {
                    total =
                      +this.barChartSeries.series1[e.dataIndex] +
                      +this.barChartSeries.series2[e.dataIndex] +
                      +this.barChartSeries.series3[e.dataIndex];
                  }
                  return total + "家";
                }, //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "设计师",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: false, //开启显示
                position: "top", //在上方显示
                offset: [-20, 0],
                formatter: (e) => {
                  // console.log("firstee", e)
                  let total = 0;
                  if (this.barChartSeries.series1) {
                    total =
                      +this.barChartSeries.series1[e.dataIndex] +
                      +this.barChartSeries.series2[e.dataIndex] +
                      +this.barChartSeries.series3[e.dataIndex];
                  }
                  return total + "家";
                }, //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
          ],
        },
        option2: {
          xAxis: {
            show: false,
            max: "dataMax",
          },
          yAxis: {
            show: false,
            type: "category",
            data: ["1", "2", "3", "4", "5"],
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: 2, // only the largest 3 bars will be displayed
          },
          grid: {
            left: 10,
            top: 30,
            bottom: 20,
            right: 60,
          },
          series: [
            {
              // realtimeSort: true,
              name: "X",
              type: "bar",
              data: [3, 4, 5],
              label: {
                show: true,
                position: "right",
                valueAnimation: true,
                formatter: (e) => {
                  // console.log("firste", e);
                  let arr = [];
                  if (this.currentleftData[e.seriesName]) {
                    arr = [
                      this.currentleftData[e.seriesName].company_compare,
                      this.currentleftData[e.seriesName].store_compare,
                      this.currentleftData[e.seriesName].designer_compare,
                    ];
                  }
                  return `${
                    arr[e.dataIndex] >= 0
                      ? `{a| ${Math.abs(arr[e.dataIndex])}`
                      : `{b| ${Math.abs(arr[e.dataIndex])}`
                  }} ${
                    arr[e.dataIndex] > 0
                      ? "{a|↑}"
                      : arr[e.dataIndex] == 0
                      ? ""
                      : "{b|↓}"
                  } `;
                },
                rich: {
                  a: {
                    color: "#c5e1a5",
                    fontWeight: "bold",
                    fontSize: 14,
                  },
                  b: {
                    color: "#e64f4a",
                    fontWeight: "bold",
                    fontSize: 14,
                  },
                },
              },
              itemStyle: {
                color: function (params) {
                  var colorlist = ["#0057FF", "#71FFD8", "#73BBFF"];
                  return colorlist[params.dataIndex];
                },
              },
            },
          ],
          animationDuration: 0,
          animationDurationUpdate: 1000,
          animationEasing: "linear",
          animationEasingUpdate: "linear",
        },
        option3: {
          color: ["#0057FF", "#71FFD8", "#73BBFF"],
          textStyle: {
            color: "#ffffff",
          },
          tooltip: {
            trigger: "axis",
            formatter: (e) => {
              console.log("first,", e)
              return `${e[0].axisValue}日<br />
              ${e[0].marker}${e[0].seriesName} <span style="margin-left: 10px;">${e[0].value}</span><br />
              ${e[1].marker}${e[1].seriesName} <span style="margin-left: 24px;">${e[1].value}</span><br />
              ${e[2].marker}${e[2].seriesName} <span style="margin-left: 24px;">${e[2].value}</span>`
            }
            // formatter: '{b0}月：{a0} {c0}<br />{b1}月：{a1} {c1}<br />{b2}月：{a2} {c2}'
          },
          grid: {
            left: 50,
            top: 90,
          },
          legend: {
            data: ["装修公司", "材料商", "设计师"],
            textStyle: {
              color: "#ffffff",
              fontSize: "14px",
            },
            top: 0,
            right: 140,
          },
          calculable: false,
          xAxis: [
            {
              type: "category",
              axisLabel: {
                interval: 0, // 强制显示所有标签
                // rotate: 45,  // 旋转角度
                margin: 10, // 与轴线的距离
                formatter: "{value} 日",
              },
              splitArea: {
                show: true,
              },
              data: [
                "1月",
                "2月",
                "3月",
                "4月",
                "5月",
                "6月",
                "7月",
                "8月",
                "9月",
                "10月",
                "11月",
                "12月",
              ],
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          dataZoom: [
            {
              show: true,
              type: "slider",
              zoomLock: true,
              startValue: 0,
              endValue: 8,
              bottom: "0",
              left: "30",
              height: 10,
              borderColor: "rgba(0,0,0,0)",
              textStyle: {
                color: "#05D5FF",
              },
            },
          ],
          series: [
            {
              name: "装修公司",
              type: "bar",
              data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0,
              ],
              showBackground: false,
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                // offset: [20, 0],
                formatter: (e) => {
                  return e.value + "家";
                }, //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "材料商",
              type: "bar",
              data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0,
              ],
              showBackground: false,
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                formatter: "{c}家", //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "设计师",
              type: "bar",
              data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                0, 0, 0, 0, 0, 0, 0, 0,
              ],
              showBackground: false,
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                formatter: "{c}家", //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
          ],
        },
        option4: {
          color: ["#0057FF", "#71FFD8", "#73BBFF"],
          textStyle: {
            color: "#ffffff",
          },
          tooltip: {
            trigger: "axis",
            formatter: (e) => {
              console.log("first,", e)
              return `${e[0].axisValue}月<br />
              ${e[0].marker}${e[0].seriesName} <span style="margin-left: 10px;">${e[0].value}</span><br />
              ${e[1].marker}${e[1].seriesName} <span style="margin-left: 24px;">${e[1].value}</span><br />
              ${e[2].marker}${e[2].seriesName} <span style="margin-left: 24px;">${e[2].value}</span>`
            }
            // formatter: '{b0}月：{a0} {c0}<br />{b1}月：{a1} {c1}<br />{b2}月：{a2} {c2}'
          },
          grid: {
            left: 50,
            top: 30,
          },
          calculable: false,
          xAxis: [
            {
              type: "category",
              axisLabel: {
                interval: 0, // 强制显示所有标签
                // rotate: 45,  // 旋转角度
                margin: 10, // 与轴线的距离
                formatter: "{value} 月",
              },
              splitArea: {
                show: true,
              },
              data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "装修公司",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: true, //开启显示
                position: "top", //在上方显示
                offset: [20, 0],
                formatter: (e) => {
                  return e.value + "家";
                }, //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "材料商",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: false, //开启显示
                position: "top", //在上方显示
                formatter: "{c}家", //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
            {
              name: "设计师",
              type: "bar",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              showBackground: false,
              label: {
                show: false, //开启显示
                position: "top", //在上方显示
                formatter: "{c}家", //显示百分号
                textStyle: {
                  //数值样式
                  color: "#fff", //字体颜色
                  fontSize: 16, //字体大小
                },
              },
            },
          ],
        },
        current: 1,
        currentTab: 0,
        currentTabTime: 2,
        dayStartValue: "",
        dayEndValue: "",
        barChart: {},
        chart: null,
        pickerOptions: {
          //未发生的时间禁止选择
          disabledDate(time) {
            // 获取当前日期的年月日
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();

            // 获取要检查的日期的年月日
            const targetYear = time.getFullYear();
            const targetMonth = time.getMonth();

            // 如果年份或月份不等于当前日期，则禁用该日期
            return targetYear !== currentYear || targetMonth !== currentMonth;
          },
        },
        dataMonthTime: "",
        dataWeekTime: "",
        pickerOptionsWeek: {
          disabledDate(time) {
            // 获取当前日期的年月日和星期
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();
            const currentWeekday = currentDate.getDay(); // 0 表示星期天，1 表示星期一，以此类推

            // 获取要检查的日期的年月日和星期
            const targetYear = time.getFullYear();
            const targetMonth = time.getMonth();
            const targetDay = time.getDate();
            const targetWeekday = time.getDay();

            // 计算当前日期距离本周一的天数
            const daysUntilMonday = currentWeekday === 0 ? 6 : currentWeekday - 1;

            // 计算本周一的日期
            const mondayDate = new Date(
              currentYear,
              currentMonth,
              currentDay - daysUntilMonday
            );

            // 如果年份或月份不等于当前日期，或者日期早于本周一，则禁用该日期
            return (
              targetYear !== currentYear ||
              targetMonth !== currentMonth ||
              time < mondayDate
            );
          },
        },
        currentCity: {
          name: "全部市",
          id: "360000",
        },
        cityList: [],
        currentleftData: {},
        currentCompareData: {},
        xIndex: 0,
        content_text: "昨日",
        currentCitysGdp: [],
        windowAspectRatio: 0,
        cardChart: null,
        currentDate: this.formatDate(new Date()),
        barChartSeries: {
          series1: [],
          series2: [],
          series3: [],
        },
        totalData: {
          apply_count: 0,
          examine_count: 0,
          examine_passed_count: 0,
          sign_count: 0,
        },
        months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        years: [2023, 2024],
        selectYear: 2024,
        selectMonth: 1,
        currentArea: {},
        currentAreaData: {},
        timeList: ["今日", "本周", "本月", "本年度", "上年度"],
      };
    },
    mounted() {
      this.getCitys();
      this.initCharts();
      this.initMonthChart();
      this.initYearChart();
      // console.log("first", getDaysInMonth(2024, 1))
      this.chart = echarts.init(this.$refs.MapArea);
      this.initEcharts();
      this.timeLeftDataInit();
      this.handleResize();
      this.current == 0 ? this.getCityGdp("350000") : this.getCityGdp("360000");
      // 使用定时器每秒更新一次日期
      this.timer = setInterval(() => {
        this.currentDate = this.formatDate(new Date());
      }, 1000);
      window.onresize = () => {
        this.barChart && this.barChart.resize();
        this.monthChart && this.monthChart.resize();
        this.yearChart && this.yearChart.resize();
        this.chart && this.chart.resize();
        this.cardChart && this.cardChart.resize();
        this.handleResize();
      };
    },
    beforeDestroy() {
      this.chart.dispose();
      this.barChart.dispose();
      this.monthChart.dispose();
      this.yearChart.dispose();
      this.cardChart.dispose();
      clearInterval(this.timer);
    },
    methods: {
      timeLeftDataInit() {
        this.getleftData(
          this.currentCity.id,
          this.currentTabTime == 0
            ? "day"
            : this.currentTabTime == 1
            ? "week"
            : this.currentTabTime == 2
            ? "month"
            : this.currentTabTime == 3
            ? "year"
            : "lastyear"
        );
        this.getTotalData(
          this.currentCity.id,
          this.currentTabTime == 0
            ? "day"
            : this.currentTabTime == 1
            ? "week"
            : this.currentTabTime == 2
            ? "month"
            : this.currentTabTime == 3
            ? "year"
            : "lastyear"
        );
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = this.padZero(date.getMonth() + 1);
        const day = this.padZero(date.getDate());
        const hours = this.padZero(date.getHours());
        const minutes = this.padZero(date.getMinutes());
        const seconds = this.padZero(date.getSeconds());
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      padZero(value) {
        return value < 10 ? "0" + value : value;
      },
      initCardChart(index, key) {
        let chartDom = this.$refs.cardChart[index];
        let cardChart = echarts.init(chartDom);
        this.cardChart = cardChart;
        let arr = [
          Math.abs(this.currentleftData[key].company_compare),
          Math.abs(this.currentleftData[key].store_compare),
          Math.abs(this.currentleftData[key].designer_compare),
        ];
        this.option2.series[0].data = arr;
        this.option2.series[0].name = key;
        this.option2 && cardChart.setOption(this.option2);
      },
      initMonthChart() {
        this.option3.xAxis[0].data = getDaysInMonth(
          this.selectYear,
          this.selectMonth
        );
        let chartDom = this.$refs.monthChart;
        let monthChart = echarts.init(chartDom);
        this.monthChart = monthChart;
        this.option3 && monthChart.setOption(this.option3);
      },
      initYearChart(index, key) {
        let chartDom = this.$refs.yearChart;
        let yearChart = echarts.init(chartDom);
        this.yearChart = yearChart;
        this.option4 && yearChart.setOption(this.option4);
      },
      handleResize() {
        // 获取窗口宽度和高度
        var windowWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        var windowHeight =
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight;
        // 计算窗口长宽比
        var windowAspectRatio = windowWidth / windowHeight;
        // 打印结果或执行其他逻辑
        this.windowAspectRatio = windowAspectRatio;
      },
      getAreaDetail(adcode, year, month) {
        this.$http
          .post("dashboard/distCount", {
            adcode,
            year,
            month,
            type: this.currentTab + 1,
          })
          .then((res) => {
            if (res.data.code == 1) {
              this.currentAreaData = res.data.data;
              let series1 = [];
              let series2 = [];
              let series3 = [];
              let series4 = [];
              let series5 = [];
              let series6 = [];
              for (let key in this.currentAreaData.days) {
                if (this.currentAreaData.days.hasOwnProperty(key)) {
                  // console.log(key, this.currentleftData[key]);
                  series1.push(this.currentAreaData.days[key].company_count);
                  series2.push(this.currentAreaData.days[key].store_count);
                  series3.push(this.currentAreaData.days[key].designer_count);
                }
              }

              for (let key in this.currentAreaData.months) {
                if (this.currentAreaData.months.hasOwnProperty(key)) {
                  // console.log(key, this.currentleftData[key]);
                  series4.push(this.currentAreaData.months[key].company_count);
                  series5.push(this.currentAreaData.months[key].store_count);
                  series6.push(this.currentAreaData.months[key].designer_count);
                }
              }

              this.option3.series[0].data = series1;
              this.option3.series[1].data = series2;
              this.option3.series[2].data = series3;
              this.option4.series[0].data = series4;
              this.option4.series[1].data = series5;
              this.option4.series[2].data = series6;
              this.initMonthChart();
              this.initYearChart();
              // console.log("this.currentAreaData", this.currentAreaData)
            }
          });
      },
      getCityGdp(id) {
        this.$http.post("dashboard/cityInfo", { adcode: id }).then((res) => {
          if (res.data.code === 1) {
            this.currentCitysGdp = res.data.data;
          } else {
            this.$message.error("获取数据失败");
          }
        });
      },
      getCitys() {
        let id = 35;
        if (this.current === 0) {
          id = 35;
        } else {
          id = 36;
        }
        let cityList =
          require(`@/assets/map/geometryProvince/${id}.json`).features;
        if (this.current === 0) {
          this.cityList = cityList
            .filter((it) => it.properties.name == "厦门市")
            .map((item) => {
              return {
                id: item.properties.id + "00",
                name: item.properties.name,
              };
            });
          // this.cityList.unshift({
          //   id: 350000,
          //   name: "全部市",
          // });
        } else {
          this.cityList = cityList.map((item) => {
            return {
              id: item.properties.id + "00",
              name: item.properties.name,
            };
          });
          this.cityList.unshift({
            id: 360000,
            name: "全部市",
          });
        }
        console.log("cityList", this.cityList);
      },
      getleftData(id, date) {
        let address = "dashboard/applyCount";
        if (this.currentTab == 0) {
          address = "dashboard/applyCount";
        } else if (this.currentTab == 1) {
          address = "dashboard/examineCount";
        } else if (this.currentTab == 2) {
          address = "dashboard/examinePassedCount";
        } else if (this.currentTab == 3) {
          address = "dashboard/signCount";
        }
        this.$http.post(address, { adcode: id, type: date }).then((res) => {
          console.log("res", res);
          if (res.data.code === 1) {
            this.currentleftData = res.data.data;
            let barXAxis = [];
            let series1 = [],
              series2 = [],
              series3 = [];
            for (let key in this.currentleftData) {
              if (this.currentleftData.hasOwnProperty(key)) {
                console.log(key, this.currentleftData[key]);
                barXAxis.push(this.currentleftData[key].area);
                series1.push(this.currentleftData[key].company_count);
                series2.push(this.currentleftData[key].store_count);
                series3.push(this.currentleftData[key].designer_count);
              }
            }
            this.option1.xAxis[0].data = barXAxis;
            this.option1.series[0].data = series1;
            this.option1.series[1].data = series2;
            this.option1.series[2].data = series3;

            this.barChartSeries.series1 = series1;
            this.barChartSeries.series2 = series2;
            this.barChartSeries.series3 = series3;

            // this.currentCompareData =
              // this.currentleftData[Object.keys(this.currentleftData)[this.xIndex]];
              
            setTimeout(() => {
              let index = 0;
              for (var key in this.currentleftData) {
                if (this.currentleftData.hasOwnProperty(key)) {
                  this.$refs.cardChart[index] && this.initCardChart(index, key);
                  index++;
                  // console.log(key + ": " + this.currentleftData[key]);
                }
              }

              console.log("first", this.barChartSeries.series1);

              if (!this.barChartSeries.series1) {
                return;
              }

              if (
                +this.barChartSeries.series1[0] >= +this.barChartSeries.series2[0] &&
                +this.barChartSeries.series1[0] >= +this.barChartSeries.series3[0]
              ) {
                this.option1.series[0].label.show = true;
                this.option1.series[1].label.show = false;
                this.option1.series[2].label.show = false;
              } else if (
                +this.barChartSeries.series2[0] >= +this.barChartSeries.series1[0] &&
                +this.barChartSeries.series2[0] >= +this.barChartSeries.series3[0]
              ) {
                this.option1.series[0].label.show = false;
                this.option1.series[1].label.show = true;
                this.option1.series[2].label.show = false;
              } else if (
                +this.barChartSeries.series3[0] >= +this.barChartSeries.series1[0] &&
                +this.barChartSeries.series3[0] >= +this.barChartSeries.series2[0]
              ) {
                this.option1.series[0].label.show = false;
                this.option1.series[1].label.show = false;
                this.option1.series[2].label.show = true;
              }else{
                this.option1.series[0].label.show = true;
                this.option1.series[1].label.show = false;
                this.option1.series[2].label.show = false;
              }
              this.initCharts();
            }, 0);
          } else {
            this.$message.error("获取数据失败");
          }
        });
      },
      getTotalData(id, date) {
        this.$http
          .post("dashboard/count", { adcode: id, type: date })
          .then((res) => {
            // console.log("res111", res);
            if (res.data.code === 1) {
              this.totalData = res.data.data;
            } else {
              this.$message.error("获取数据失败");
            }
          });
      },
      getRegionValue(regionName) {
        const regionData = this.currentCitysGdp.find(
          (data) => data.name === regionName
        );
        if (regionData) {
          return `${regionData.name}${
            regionData.people ? "\r人口数量：" + regionData.people : ""
          }${regionData.gdp ? "\nGDP：" + regionData.gdp : ""} ${
            regionData.area ? "\n面积：" + regionData.area : ""
          }`;
        } else {
          return regionName;
        }
      },
      dataTimeChange() {},
      change() {},
      dropdownYearTap(it) {
        this.selectYear = it;
        this.getAreaDetail(
          this.currentArea.id,
          this.selectYear,
          this.selectMonth
        );
      },
      dropdownMonthTap(it) {
        this.selectMonth = it;
        this.getAreaDetail(
          this.currentArea.id,
          this.selectYear,
          this.selectMonth
        );
      },
      dropdownTap(it) {
        console.log("it", it);
        this.currentCity = it;
        this.currentClick = this.currentCity.name;
        if (it.name == "全部市") {
          this.currentLevel = 0;
        } else {
          this.currentLevel = 1;
        }
        this.getCityGdp(this.currentCity.id);
        this.initEcharts();
        this.timeLeftDataInit();
      },
      tabTap(index) {
        this.current = index;
        this.currentLevel = 0;
        if (this.current == 0) {
          this.currentCity.id = 350200;
          this.currentCity.name = "厦门市";
        } else {
          this.currentCity.id = 360000;
          this.currentCity.name = "全部市";
        }
        this.getCityGdp(this.currentCity.id);
        this.getCitys();
        this.initEcharts();
        this.timeLeftDataInit();
      },
      tagTabTap(index) {
        this.currentTab = index;
        this.initEcharts();
        this.timeLeftDataInit();
        if (this.currentLevel == 2) {
          this.getAreaDetail(
            this.currentArea.id,
            this.selectYear,
            this.selectMonth
          );
        }
      },
      tagTimeTap(index) {
        this.currentTabTime = index;
        this.content_text =
          this.currentTabTime == 0
            ? "昨日"
            : this.currentTabTime == 1
            ? "上周"
            : this.currentTabTime == 2
            ? "上月"
            : this.currentTabTime == 3
            ? "去年"
            : "前年";
        this.timeLeftDataInit();
      },
      backChartTap() {
        console.log("this.currentLevel", this.currentLevel);
        if (this.currentLevel == 0) {
        } else if (this.currentLevel == 1) {
          this.currentLevel = 0;
          if (this.current == 0) {
            this.currentCity.id = 350200;
            this.currentCity.name = "厦门市";
          } else {
            this.currentCity.id = 360000;
            this.currentCity.name = "全部市";
          }
          this.getCityGdp(this.currentCity.id);
          this.timeLeftDataInit();
          this.provinceData();
          this.chart.setOption(this.option);
        } else if (this.currentLevel == 2) {
          this.currentLevel = 1;
          this.cityData();
          this.chart.setOption(this.option);
        }
      },
      barChartTap(params) {
        console.log("点击了柱状图的第" + params.dataIndex + "项");
      },
      initCharts() {
        let chartDom = this.$refs.barChart;
        let barChart = echarts.init(chartDom);
        this.barChart = barChart;
        console.log("first111", this.$refs.barChart, barChart);
        this.option1 && barChart.setOption(this.option1);

        // 添加点击事件
        // barChart.getZr().on('click', params => {
        //   let pointInPixel = [params.offsetX, params.offsetY]
        //   if (barChart.containPixel('grid', pointInPixel)) {
        //     let xIndex = barChart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]
        //     console.log(xIndex)
        //     this.xIndex = xIndex
        //   }
        // })
      },
      initEcharts() {
        //省份下市区
        if (!this.currentLevel) {
          this.provinceData();
        }

        //市区下县区
        if (this.currentLevel == 1) {
          this.cityData();
        }

        if (this.currentLevel == 2) {
          this.countriesData();
        }
        this.chart.setOption(this.option);
        this.chart.off("click"); //解决点击地图会触发两次问题
        this.chart.on("click", (params) => {
          // console.log(params, this.currentLevel, params.region.type);
          if(this.current == 0 && params.name !== "厦门市" && this.currentLevel == 0) {
            return; 
          }

          if (!params.region) {
            return;
          }
          this.currentLevel =
            params.region.type == "城市"
              ? 1
              : params.region.type == "县区"
              ? 2
              : params.region.type == "县区"
              ? 3
              : 0;
          console.log("this.currentLevel-params", this.currentLevel, params);
          if (this.currentLevel == 2) {
            this.currentArea = {
              id: params.region.id,
              name: params.name,
            };
            this.currentClick2 = params.name;
            this.getAreaDetail(
              this.currentArea.id,
              this.selectYear,
              this.selectMonth
            );
          } else {
            this.currentClick = params.name;
            this.currentCity = {
              id: params.region.id + "00",
              name: params.name,
            };
            this.getCityGdp(this.currentCity.id);
            this.timeLeftDataInit();
          }
          this.currentLevel != 3 && this.initEcharts(); //县区下没有别的级别
        });
      },
      provinceData() {
        this.currentTitle = "";
        let id = 36;
        if (this.current == 0) {
          id = 35;
        } else {
          id = 36;
        }
        //console.log(id)  //找出  @/core/map/china.json 里面内蒙古id  同事在此路径geometryProvince文件下  id.json就是对应该省份对应的市级数据
        let cityList = require(`@/assets/map/geometryProvince/${id}.json`); //获取城市数据
        regionsList = cityList.features.map((item) => ({
          name: item.properties.name,
          type: "城市",
          id: +item.properties.id,
          itemStyle: {
            normal: {
              shadowColor: "#0070BC",
              shadowOffsetY: 25,
              shadowBlur: -25,
            },
          },
        }));
        this.currentCityList = cityList;
        //console.log(regionsList)
        let outCityList = ["厦门市"]; //剔除不需要城市
        let objMap = {
          ...chinaJson,
          features: cityList.features.filter(
            (item) => !outCityList.includes(item.properties.name)
          ), //这里可以过滤不需要展示城市，可以和regionsList联动使用
        };
        if (this.current == 0) {
          objMap = {
            ...chinaJson,
            features: cityList.features.filter((item) =>
            (item) => !outCityList.includes(item.properties.name)
            ), //这里可以过滤不需要展示城市，可以和regionsList联动使用
          };
        } else {
          objMap = {
            ...chinaJson,
            features: cityList.features.filter(
              (item) => !outCityList.includes(item.properties.name)
            ), //这里可以过滤不需要展示城市，可以和regionsList联动使用
          };
        }
        echarts.registerMap("chinaJson", objMap); //chinaJSon自定义名称，但是无法显示右下角南海诸岛， 可以设置成china
        this.option.geo.regions = regionsList;
      },
      cityData() {
        this.currentTitle = "返回地级市";
        console.log(
          "currentCityList",
          this.currentCityList,
          this.currentClick,
          this.currentClick2
        );
        let { id } = this.currentCityList.features.filter(
          (item) => this.currentClick == item.properties.name
        )[0].properties;
        console.log("id", id);
        //console.log(id, this.currentCityList.features.filter((item) => this.currentClick == item.properties.name)[0])
        let areaList = require(`@/assets/map/geometryCouties/${id}00.json`); //获取区县数据
        this.currentAreaList = areaList;
        console.log("areaList111", areaList);
        regionsList = areaList.features.map((item) => ({
          name: item.properties.name,
          type: "县区",
          id: +item.properties.id,
        }));

        let outAreaList = []; //剔除不需要县区
        let objMap = {
          ...chinaJson,
          features: areaList.features.filter(
            (item) => !outAreaList.includes(item.properties.name)
          ), //这里可以过滤不需要展示县区，可以和regionsList联动使用
        };
        echarts.registerMap("chinaJson", objMap); //chinaJSon自定义名称，但是无法显示右下角南海诸岛， 可以设置成china
        this.option.geo.regions = regionsList;
      },
      countriesData() {
        console.log(this.currentLevel, this.currentClick, this.currentCityList);
        this.currentTitle = "返回行政区";
        let { id } = this.currentAreaList.features.filter(
          (item) => this.currentClick2 == item.properties.name
        )[0].properties;
        // console.log("id2", id)
        let resultString = id.toString().slice(0, -2);
        // 在末尾补00
        let finalResult = resultString + "00";
        //console.log(id, this.currentCityList.features.filter((item) => this.currentClick == item.properties.name)[0])
        let areaList = require(`@/assets/map/geometryCouties/${finalResult}.json`); //获取区县数据

        regionsList = areaList.features.map((item) => ({
          id: item.properties.id,
          name: item.properties.name,
          type: "县区",
        }));
        regionsList = regionsList.filter((item) => {
          return id == item.id;
        });
        // let outAreaList = []; //剔除不需要县区
        let objMap = {
          ...chinaJson,
          features: areaList.features.filter((item) => id == item.properties.id), //这里可以过滤不需要展示县区，可以和regionsList联动使用
        };
        echarts.registerMap("chinaJson", objMap); //chinaJSon自定义名称，但是无法显示右下角南海诸岛， 可以设置成china
        this.option.geo.regions = regionsList;
      },
    },
  };
</script>

<style scoped lang="scss">
  .area {
    display: flex;
    margin-top: 15px;
    margin-left: 20px;
    position: absolute;
    z-index: 10;
  }

  .areaChart {
    display: flex;
    flex-direction: column;
    // width: 70vw;
  }
  .monthChart {
    width: 430px;
    height: 180px;
    margin-top: 20px;
  }
  .yearChart {
    width: 430px;
    height: 160px;
    margin-top: 5px;
  }

  .home-page {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
  }

  .back {
    color: #06ffff;
    display: flex;
    align-items: center;
    font-size: 7px;
    cursor: pointer;
    visibility: hidden;
    .back-icon {
      width: 5px;
      margin-right: 4px;
    }
  }

  .current-time {
    color: #06ffff;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .current-time-postion {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .home {
    width: 100%;
    height: 100%;
    position: absolute;
    // background: #051e3c;
    // background-image: url("../assets/bg.png"); /* 替换为实际图片路径 */
    // background-size: cover; /* 控制背景图片的尺寸，cover表示尽量覆盖整个容器 */
    // background-position: center; /* 控制背景图片的位置，center表示居中 */
    // background-repeat: no-repeat; /* 禁止背景图片重复 */
  }

  // @media only screen and (max-width: 1921px) {
  //   .home {
  //     scale: (0.85);
  //   }
  // }

  .home-bg {
    width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }

  .title {
    font-size: 6px;
    width: 515px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-img {
      width: 100%;
      height: 100%;
    }
  }

  .tab {
    display: flex;
    color: #fff;
    margin-left: 10px;
    font-size: 9px;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 63px;

    .tab-it {
      margin-top: 6px;
      cursor: pointer;
    }

    .tab-it:nth-child(2) {
      margin-left: 30px;
    }

    .tab-item {
    }

    .tab-item-active {
      color: #06ffff;
    }
  }

  .container {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .dropdown {
      padding: 5px 10px;
      // background: #0597c9;
      background-image: url("../assets/select-bg.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 3px;
      font-size: 6px;
      .el-dropdown-link {
        color: #fff;
        display: flex;
        align-items: center;
      }

      .el-dropdown-img {
        width: 12px;
        height: 10px;
      }
    }
    .el-icon-arrow-down {
      font-size: 6px;
    }
  }

  .container-left {
    display: flex;
    flex-direction: column;

    .map {
      width: 236px;
      height: 300px;
      background-image: url("../assets/border2.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      margin: 0px 30px;
      display: flex;
      justify-content: center;

      .map-chart {
        position: relative;
        width: 94%;
        height: 79.7%;
        margin-top: 40px;
        .mapImg {
          width: 74px;
          height: 82px;
          position: relative;
          bottom: 65px;
          left: 140px;
          z-index: -2;
          .mapImg-bg4 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }

          .mapImg-map {
            width: 90%;
            height: 70%;
            position: absolute;
            top: 15px;
            left: 3px;
          }
        }

        .img-chart {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          z-index: -2;
        }
      }

      .map-title {
        color: #fff;
        font-size: 11px;
        position: absolute;
        left: 92px;
        top: 8px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .container-right {
    width: 416px;
    height: 360px;
    margin: 10px 20px 10px 0px;
    position: relative;
    .container-right-title {
      color: #fff;
      font-size: 11px;
      position: absolute;
      left: 182px;
      top: -6px;
    }

    .container-right-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -3;
    }
  }

  .content-nav {
    display: flex;
    align-items: center;
    margin: 0px 30px 0px;
    position: absolute;
    top: 15px;
    z-index: 10;
  }

  .MapArea {
    width: 100%;
    height: 90%;
  }

  .el-dropdown-menu {
    background: #165aaf !important;
    border: 1px solid #165aaf;
    box-shadow: inset 1px 1px 2px 2px #164580;
  }

  ::v-deep .popper__arrow::after {
    border-bottom-color: #165aaf !important;
  }

  ::v-deep .popper__arrow {
    border-bottom-color: #165aaf !important;
  }

  .el-dropdown-menu__item {
    color: #fff !important;
  }
  .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #000 !important;
  }

  .el-dropdown-link {
    cursor: pointer;
    color: #000;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .tag-tab {
    display: flex;
    background-image: url("../assets/border1.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 236px;
    height: 72px;
    margin: 10px 30px 0px;
    position: relative;

    .tag-tab-title {
      font-size: 9px;
    }

    .tag-title {
      color: #fff;
      font-size: 11px;
      position: absolute;
      left: 92px;
      top: -8px;
    }

    .tag-tab-box {
      width: 32px;
      height: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #00ff1e;
      position: absolute;
      top: 16px;
    }

    .tag-tab-box:nth-child(1) {
      left: 20px;
    }

    .tag-tab-box:nth-child(2) {
      left: 70px;
    }

    .tag-tab-box:nth-child(3) {
      left: 120px;
    }

    .tag-tab-box:nth-child(4) {
      left: 170px;
    }

    .tag-item {
      width: 32px;
      height: 32px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tag-line:nth-child(5) {
      width: 21px;
      height: 1px;
      background: #06ffff;
      position: absolute;
      top: 35px;
      left: 50.5px;
    }

    .tag-line:nth-child(6) {
      width: 21px;
      height: 1px;
      background: #06ffff;
      position: absolute;
      top: 35px;
      left: 100.5px;
    }

    .tag-line:nth-child(7) {
      width: 21px;
      height: 1px;
      background: #06ffff;
      position: absolute;
      top: 35px;
      left: 150.5px;
    }

    .tag-item:nth-child(1) {
    }

    .tag-item:nth-child(2) {
    }

    .tag-item:nth-child(3) {
    }

    .tag-item:nth-child(4) {
    }

    .tag-bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .tag-tab-img {
      width: 94%;
      height: 80%;
      position: absolute;
      top: 10%;
      right: 3%;
      z-index: -2;
    }

    .tag-tab-item-with {
      // width: 40px;
      // height: 12px;
      // border: 1.1px solid #0099ff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 9px;
      cursor: pointer;
    }

    .tag-tab-item {
      @extend .tag-tab-item-with;
      color: #fff;
    }

    .tag-tab-item-active {
      @extend .tag-tab-item-with;
      // background: #5386b7;
      color: #0a28de;
    }
  }

  .tag-time {
    display: flex;
    margin-left: 5px;

    .tag-time-item-with {
      width: 30px;
      height: 11px;
      padding: 1.1px 2px;
      border: 1px solid #0099cc;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      font-size: 5px;
      position: relative;
      border-top: 1px dashed rgba(255, 255, 255, 0.6);
      border-bottom: 1px dashed rgba(255, 255, 255, 0.6);
      cursor: pointer;
    }

    .tag-time-img {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .tag-time-item {
      @extend .tag-time-item-with;
    }

    .tag-time-item-active {
      @extend .tag-time-item-with;
      color: #fff;
      background: #5386b7;
    }
  }

  .tag-time-select {
    display: flex;
    align-items: center;
    color: #fff;
    padding: 5px 10px 0;
    &-title {
      margin-right: 5px;
      font-size: 5px;
    }
  }

  .day-select .el-date-editor {
    font-size: 6px;
    width: 80px !important;
  }

  .month-select .el-date-editor {
    font-size: 5px !important;
    width: 120px !important;
  }

  ::v-deep .el-input__inner {
    height: 2vw;
  }

  ::v-deep .el-range-editor--small.el-input__inner {
    height: 2vw;
  }

  // ::v-deep .el-range-input {
  //   font-size: 5px;
  // }
  ::v-deep .el-range-editor--small .el-range-input {
    font-size: 5px;
  }

  ::v-deep .el-range-editor--small .el-range-separator {
    font-size: 5px;
    display: flex;
    align-items: center;
  }

  ::v-deep .el-date-editor .el-range__icon {
    font-size: 5px;
    height: 10px;
    display: flex;
    align-items: center;
  }

  ::v-deep .month-select .el-input__icon {
    line-height: 15px;
    height: 10px;
    display: flex;
    align-items: center;
  }

  ::v-deep .day-select .el-input__icon {
    line-height: 15px;
    height: 5px;
  }

  ::v-deep .el-picker-panel__content {
    max-height: 90px;
  }

  ::v-deep .time-select-item {
    font-size: 5px;
  }

  // .container-right-content{
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 20px;
  //   .areaChart{
  //     padding: 0px 10px;
  //     width: 70vw;
  //     .monthChart{
  //       width: 430px;
  //       height: 178px;
  //     }
  //   }
  // }

  .content {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .charts {
      padding: 0px 10px;
      width: 70vw;
      .barChart {
        width: 430px;
        height: 178px;
      }
    }
  }

  .card {
    display: flex;
    flex-wrap: wrap;
    .card-item {
      color: #fff;
      // padding: 0 5px 5px 5px;
      // background: #0c4076;
      font-size: 5px;
      margin: 2px 1.1px;
      width: 76px;
      height: 45px;
      white-space: nowrap;
      position: relative;

      .city-name {
        position: absolute;
        top: -1px;
        left: 3px;
      }

      .cardChart {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      div {
        margin-top: 2px;
      }
      .card-item-per {
        font-size: 6px;
      }
    }
  }

  .card-box {
    height: 150px;
    overflow: auto;
  }

  .backChart {
    color: #00ff1e;
    // font-weight: 700;
    position: absolute;
    top: 50px;
    left: 20px;
    z-index: 99;
    cursor: pointer;
  }

  .card-item-per-top {
    font-size: 6px;
    color: greenyellow;
    font-weight: bold;
  }

  .card-item-per-bottom {
    font-size: 6px;
    color: rgb(187, 63, 63);
    font-weight: bold;
  }

  /* 隐藏滚动条但保留滚动功能 */
  body {
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: transparent transparent; /* Firefox */
    overflow-y: scroll; /* 允许滚动 */
  }

  /* Webkit 浏览器（Chrome, Safari） */
  ::-webkit-scrollbar {
    width: 6px; /* 滚动条宽度 */
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent; /* 滑块颜色 */
  }

  ::-webkit-scrollbar-track {
    background-color: transparent; /* 滚动槽颜色 */
  }
</style>
