<template>
  <div class="hello">
    <!-- <div >123123</div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      mapLastName: "", //上一步地图显示内容
      mapName: "", //地图默认展示层级
      mapCity: {
        //中文名与json 文件对应表
        江西省: "jiangxi",
        抚州市: "fuzhou",
        赣州市: "ganzhou",
        吉安市: "jian",
        景德镇市: "jingdezhen",
        九江市: "jiujiang",
        南昌市: "nanchang",
        萍乡市: "pingxiang",
        上饶市: "shangrao",
        新余市: "xinyu",
        宜春市: "yichun",
        鹰潭市: "yingtan",

        //市区级
        婺源县: "shangrao_wyx",
        鄱阳县: "shangrao_pyx",
        玉山县: "shangrao_yushanxian",
        余干县: "shangrao_ygx",
        广丰区: "shangrao_gfq",
        弋阳县: "shangrao_yyx",
        万年县: "shangrao_wnx",
        德兴市: "shangrao_dxs",
        广信区: "shangrao_gxq",
        横峰县: "shangrao_hfx",
        信州区: "shangrao_xzq",
        铅山县: "shangrao_yzx",

        修水县: "jiujiang_xsx",
        武宁县: "jiujiang_wnx",
        永修县: "jiujiang_yxx",
        共青城市: "jiujiang_gqcs",
        瑞昌市: "jiujiang_rcs",
        德安县: "jiujiang_dax",
        柴桑区: "jiujiang_csq",
        庐山市: "jiujiang_lss",
        濂溪区: "jiujiang_lxq",
        彭泽县: "jiujiang_pzx",
        都昌县: "jiujiang_dcx",
        湖口县: "jiujiang_hkx",
        浔阳区: "jiujiang_xyq",

        贵溪市: "yingtan_guixi",
        月湖区: "yingtan_yuehu",
        余江区: "yingtan_yujiang",

        上栗县: "pingxiang_shangli",
        安源区: "pingxiang_anyuan",
        莲花县: "pingxiang_lianhua",
        芦溪县: "pingxiang_luxi",
        湘东区: "pingxiang_xiangdong",

        浮梁县: "jingdezhen_fuliang",
        珠山区: "jingdezhen_zhushan",
        昌江区: "jingdezhen_changjiang",
        乐平市: "jingdezhen_leping",

        丰城市: "yichun_fcs",
        高安市: "yichun_gas",
        樟树市: "yichun_zss",
        铜鼓县: "yichun_tgx",
        万载县: "yichun_wzx",
        奉新县: "yichun_fxx",
        上高县: "yichun_sgx",
        靖安县: "yichun_jax",
        宜丰县: "yichun_yfx",
        袁州区: "yichun_yzq",

        安义县: "nanchang_ayx",
        东湖区: "nanchang_dhq",
        红谷滩区: "nanchang_hgtq",
        进贤县: "nanchang_jxx",
        南昌县: "nanchang_ncx",
        青山湖区: "nanchang_qshq",
        青云谱区: "nanchang_qypq",
        西湖区: "nanchang_xhq",
        新建区: "nanchang_xjq",

        分宜县: "xinyu_fyx",
        渝水区: "xinyu_ysq",

        临川区: "fuzhou_lcq",
        南丰县: "fuzhou_nfx",
        东乡区: "fuzhou_dxq",
        南城县: "fuzhou_ncx",
        广昌县: "fuzhou_gcx",
        金溪县: "fuzhou_jxx",
        资溪县: "fuzhou_zxx",
        乐安县: "fuzhou_lax",
        黎川县: "fuzhou_lcx",
        崇仁县: "fuzhou_crx",
        宜黄县: "fuzhou_yhx",

        泰和县: "jian_thx",
        井冈山市: "jian_jgss",
        遂川县: "jian_scx",
        永丰县: "jian_yfx",
        新干县: "jian_xgx",
        永新县: "jian_yxx",
        万安县: "jian_wax",
        安福县: "jian_afx",
        吉水县: "jian_jsx",
        吉安县: "jian_jax",
        峡江县: "jian_xjx",
        吉州区: "jian_jzq",
        青原区: "jian_qyq",

        瑞金市: "ganzhou_rjs",
        于都县: "ganzhou_ydx",
        兴国县: "ganzhou_xgx",
        信丰区: "ganzhou_xfq",
        南康区: "ganzhou_nkq",
        石城县: "ganzhou_scx",
        宁都县: "ganzhou_ndx",
        寻乌县: "ganzhou_xwx",
        龙南市: "ganzhou_lns",
        全南县: "ganzhou_qnx",
        安远县: "ganzhou_ayx",
        会昌县: "ganzhou_hcx",
        大余县: "ganzhou_dyx",
        崇义县: "ganzhou_cyx",
        定南县: "ganzhou_dnx",
        赣县区: "ganzhou_gxq",
        上犹县: "ganzhou_syx",
        章贡区: "ganzhou_zgq",
      },
      isFirstLoad: true, //是否是第一次初始化加载
    };
  },
  methods: {
  },
  mounted() {
  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
